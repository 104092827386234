import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";

import Section from "@components/Section";

import * as styles from "./styles.module.css";

const TeamImageBlock = ({ backgroundColor, image }) => {
  const { asset, alt } = image;

  return (
    <Section
      as="section"
      className={styles.container}
      backgroundColor={backgroundColor?.value}
    >
      <div className={styles.imageContainer}>
        <GatsbyImage image={asset?.gatsbyImageData} alt={alt} />
      </div>
    </Section>
  );
};

export default TeamImageBlock;
