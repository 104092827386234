import React from "react";
import { convertBgImageToSources } from "@utils/templateHelpers";

import TextBox from "@components/TextBox";
import Section from "@components/Section";

import * as styles from "./styles.module.css";

const AboutBlock = ({ backgroundImage, content }) => {
  const sources = convertBgImageToSources(
    backgroundImage,
    "(min-width: 768px)",
  );

  return (
    <Section className={styles.container} backgroundImageSources={sources}>
      <TextBox {...content} className={styles.box} />
    </Section>
  );
};

export default AboutBlock;
