import React from "react";

import TextBox from "@components/TextBox";
import Section from "@components/Section";

import * as styles from "./styles.module.css";

const UpdatesBlock = ({ updates, backgroundColor }) => {
  return (
    <Section
      as="section"
      className={styles.container}
      backgroundColor={backgroundColor?.value}
    >
      {[...updates]
        .reverse()
        .slice(0, 2)
        .map((content, index) => (
          <TextBox
            {...content}
            key={content?.title}
            whiteBox={false}
            className={index % 2 === 0 ? styles.boxOne : styles.boxTwo}
          />
        ))}
    </Section>
  );
};

export default UpdatesBlock;
