import React from "react";
import { graphql } from "gatsby";

import AboutPage from "@components/AboutPage";

const About = ({ data }) => {
  const { pageData } = data;
  return <AboutPage {...pageData} />;
};

export const query = graphql`
  query AboutPageQuery {
    pageData: sanityAboutPage {
      title
      intro {
        ...SanityTextBoxFragment
      }
      backgroundImage {
        ...SanityBackgroundImageFragment
      }
      updatesBlock {
        updates {
          ...SanityTextBoxFragment
        }
        backgroundColor {
          value
        }
      }
      aboutBlock {
        content {
          ...SanityTextBoxFragment
        }
        backgroundImage {
          ...SanityBackgroundImageFragment
        }
      }
      teamImageBlock {
        image {
          alt
          asset {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
            id
          }
        }
        backgroundColor {
          value
        }
      }
    }
  }
`;

export default About;
