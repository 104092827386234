import React from "react";

import TextBox from "@components/TextBox";
import AboutBlock from "@components/AboutBlock";
import UpdatesBlock from "@components/UpdatesBlock";
import Hero from "@components/Hero";
import TeamImageBlock from "@components/TeamImageBlock";

import * as styles from "./styles.module.css";

const AboutPage = ({
  title,
  backgroundImage,
  intro,
  updatesBlock,
  aboutBlock,
  teamImageBlock,
}) => {
  return (
    <>
      <Hero
        {...{
          title,
          backgroundImage,
          className: styles.hero,
        }}
      >
        <TextBox {...intro} className={styles.box} />
      </Hero>

      <UpdatesBlock {...updatesBlock} />
      <AboutBlock {...aboutBlock} />
      <TeamImageBlock {...teamImageBlock} />
    </>
  );
};

export default AboutPage;
